import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";

function LinkOutput({ outputLink, onCopy, isCopied }) {
  return (
    <section className="your-link">
      <label>Link gerado</label>
      <div className="row copy-to">
        <input
          className="column column-75"
          type="text"
          name="link"
          value={outputLink}
        />
        <CopyToClipboard text={outputLink} onCopy={onCopy}>
          <button type="submit" className="column column-25">
            Copiar o Link
          </button>
        </CopyToClipboard>
      </div>
      {isCopied && (
        <blockquote>
          <p>
            <em>Copiado!.</em>
          </p>
        </blockquote>
      )}
    </section>
  );
}

LinkOutput.defaultProps = {
  isCopied: false,
  link: ""
};

LinkOutput.propTypes = {
  onCopy: PropTypes.func.isRequired,
  isCopied: PropTypes.bool,
  link: PropTypes.string
};

export default LinkOutput;
