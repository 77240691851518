/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

function InputPhone({ link, influencer, formChangeState }) {
  return (
    <section className="inputs">
      <label htmlFor="link">Link</label>
      <input
        type="text"
        name="link"
        placeholder="Insira o link"
        value={link}
        onChange={formChangeState}
      />
      <label htmlFor="message">Nome do(a) influencer</label>
      <input
        type="text"
        label="influencer"
        placeholder="Insira o nome"
        name="influencer"
        value={influencer}
        onChange={formChangeState}
      />
    </section>
  );
}
export default InputPhone;
