// lib
import React, { Component, Fragment } from "react";
import "milligram/dist/milligram.min.css";

// custom
import "./App.scss";

// my components
import Header from "./components/Header";
import Inputs from "./components/Inputs";
import LinkOutput from "./components/LinkOutput";
import Footer from "./components/Footer";

export default class App extends Component {
  state = {
    message: "",
    phone: "",
    link: "",
    influencer: "",
    outputLink: "",
    isCopied: false
  };

  formChangeState = e => {
    const { name } = e.target;
    let { value } = e.target;
    this.setState({ [name]: value }, () => this.updateLink(this.state));
  };

  updateLink = ({ link, influencer }) => {
    const outputLink = `${link}?utm_source=influencer&utm_medium=cpc&utm_campaign=${encodeURI(
      influencer
    )}`;
    link && influencer && this.setState({ outputLink });
  };

  onCopy = () => {
    this.setState({ isCopied: true });
    setTimeout(() => this.setState({ isCopied: false }), 3000);
  };

  render() {
    const { influencer, isCopied, outputLink, link } = this.state;
    const { formChangeState, onCopy } = this;
    return (
      <Fragment>
        <main className="content container">
          <Header />
          <section className="form">
            <Inputs
              influencer={influencer}
              link={link}
              formChangeState={formChangeState}
            />
            <LinkOutput
              isCopied={isCopied}
              outputLink={outputLink}
              onCopy={onCopy}
            />
          </section>
          <Footer />
        </main>
      </Fragment>
    );
  }
}
